//
// Global Config
//



// Init global functions and mixins
@import "../../global/init";

// Layout Breakpoints(bootstrap responsive breakpoints)
// Define the minimum and maximum dimensions at which your layout will change, adapting to different screen sizes, for use in media queries.
$kt-media-breakpoints: (
    // Extra small screen / phone
    xs: 0,

    // Small screen / phone
    sm: 576px,

    // Medium screen / tablet
    md: 768px,

    // Large screen / desktop
    lg: 1024px,

    // Large screen / desktop
    xlg: 1200px,

    // Extra large screen / wide desktop
    xl: 1399px,

    // Large screen / desktop
    xxl: 1700px
) !default;

// Global rounded border mode
$kt-rounded: true !default;

// Border Radius
$kt-border-radius: 4px !default;

// Core icon codes(lineawesome icons: https://icons8.com/line-awesome)
$kt-action-icons: (
    close: '\f00d',
    down: '\f110',
    up: '\f113',
    left: '\f111',
    right: '\f112',
    plus: '\f2c2',
    minus: '\f28e'
) !default;

// Core bold icon codes(lineawesome icons: https://keenthemes.com/metronic/preview/default/components/icons/flaticon.html)
$kt-action-bold-icons: (
    down: '\f1a3',
    up: '\f1a5',
    left: '\f1a4',
    right: '\f19d',
    close: '\f1b2'
) !default;

// Elevate shadow
$kt-elevate-shadow: 0px 0px 13px 0px rgba(82,63,105,0.05) !default;
$kt-elevate-shadow-2: 0px 0px 13px 0px rgba(82,63,105,0.1) !default;

// Dropdown shadow
$kt-dropdown-shadow: 0px 0px 50px 0px rgba(82,63,105, 0.15) !default;

// Custom scrollbar color
$kt-scroll-color: darken(#ebedf2, 6%) !default;

// Transition
$kt-transition: all 0.3s !default;

// Modal Z-index
$kt-modal-zindex: 1050 !default;

// dropdown Z-index
$kt-dropdown-zindex: 95 !default;

// State Color
$kt-state-colors: (
    // Metronic states
    brand: (
        base: #15B7FF,
        inverse: #ffffff
    ),
    light: (
        base: #3f4254,
        inverse: #ffffff
    ),
    dark: (
        base: #282a3c,
        inverse: #ffffff
    ),
    light-gray: (
        base: #EDF0FF,
        inverse: #868AA8
    ),


    // Bootstrap states
    primary: (
        base: #15B7FF,
        inverse: #ffffff
    ),
    primary-gray: (
        base: #70A9D3,
        inverse: #ffffff
    ),
    secondary: (
        base: #868AA8,
        inverse: #ffffff
    ),
    success: (
        base: #0abb87, //1dc9b7,
        secondary: #62D500,
        inverse: #ffffff
    ),
    info: (
        base: #5578eb,
        inverse: #ffffff
    ),
    warning: (
        base: #ffb822,
        inverse: #111111
    ),
    danger: (
        base: #FF4D4D,
        secondary: #FF4A11,
        inverse: #ffffff
    ),
    closed: (
        base: #7337EE,
        inverse: #ffffff
    ),
    infoTag: (
        base: #3551C9,
        inverse: #ffffff
    ),
    neutral: (
        base: #81838B,
        inverse: #ffffff
    ),
    neutralViolet: (
        base: #333548,
        inverse: #ffffff
    ),

) !default;


// Base colors
$kt-base-colors: (
    label: (
        1: #a2a5b9,
        2: #74788d,
        3: #595d6e,
        4: #48465b
    ),
    shape: (
        1: #f0f3ff,
        2: #e8ecfa,
        3: #93a2dd,
        4: #646c9a
    ),
    grey: (
        1: #f7f8fa, //#f4f5f8
        2: #ebedf2,
        3: darken(#ebedf2, 3%),
        4: darken(#ebedf2, 6%)
    )
) !default;

// Social network colors(see: https://brandcolors.net/)
$kt-social-colors: (
    facebook: (
        base: #3b5998,
        inverse: #ffffff
    ),
    google: (
        base: #dc4e41,
        inverse: #ffffff
    ),
    twitter: (
        base: #4AB3F4,
        inverse: #ffffff
    ),
    instagram: (
        base: #517fa4,
        inverse: #ffffff
    ),
    youtube: (
        base: #b31217,
        inverse: #ffffff
    ),
    linkedin: (
        base: #0077b5,
        inverse: #ffffff
    ),
    skype: (
        base: #00aff0,
        inverse: #ffffff
    )
) !default;

// Root Font Settings
$kt-font-families: (
    regular: unquote('Poppins, Helvetica, sans-serif'),
    heading: unquote('Poppins, Helvetica, sans-serif')
) !default;

// Root Font Settings
$kt-font-size: (
    size: (
        desktop: 13px,
        tablet: 11.5px,
        mobile: 11.5px
    ),
    weight: 300
) !default;

// General Link Settings
$kt-font-color: (
    text: #525672,
    link: (
        default: kt-state-color(brand),
        hover: darken(kt-state-color(brand), 6%)
    )
) !default;

// Portlet settings
$kt-portlet: (
    min-height: (
        default: (
            desktop: 60px,
            mobile: 50px
        ),
        sm: (
            desktop: 50px,
            mobile: 40px
        ),
        lg: (
            desktop: 80px,
            mobile: 60px
        ),
        xl: (
            desktop: 100px,
            mobile: 80px
        )
    ),
    space: (
        desktop: 25px,
        mobile: 15px
    ),
    bottom-space: (
        desktop: 20px,
        mobile: 20px
    ),
    border-color: kt-base-color(grey, 2),
    bg-color: #ffffff,
    shadow: 0px 0px 30px 0px rgba(82,63,105,0.05)
) !default;

// Page padding
$kt-page-padding: (
	desktop: 15px,
	mobile: 15px
) !default;

// Page container width
$kt-page-container-width: 1380px !default;

// Custom Scroll(Perfect Scrollbar) size
$kt-custom-scroll-size: 4px !default;

//
// Layout Config
//



// Layout
$kt-aside-default-width: 240px;
$kt-aside-minimize-width: 70px;
$kt-aside-offcanvas-width: 255px;


// Layout Skins
$kt-layout-skins: (
	dark: #1e1e2d,
	light: #ffffff
);

// content bg color
$kt-content-bg-color: #F2F3F8;

// content bg color
$kt-content-border-color: #E6E6E6;

// Border Radius
$kt-border-radius: 4px;

// Page padding
$kt-page-padding: (
	desktop: 15px,
	mobile: 15px
);

// Page container width
$kt-page-container-width: 1380px;

//
// Override Bootstrap Variables
//

//
// Global variables for Bootstrap customization.
// To override any Bootstrap variable safely you can use _variables.demo.scss
//

//
// Color system
//

// Black color
$black:    											#000000 !default;

// White color
$white:    											#ffffff !default;

// Gray colors
$gray-100: 											#F3F6F9 !default;
$gray-200: 											#EBEDF3 !default;
$gray-300: 											#E4E6EF !default;
$gray-400: 											#D1D3E0 !default;
$gray-500: 											#B5B5C3 !default;
$gray-600: 											#7E8299 !default;
$gray-700: 											#5E6278 !default;
$gray-800: 											#3F4254 !default;
$gray-900: 											#181C32 !default;
$light-gray: 										#D4D4D4 !default;
$light-grey-secondary: 								#868aa8 !default;

// Dark colors
$dark:          									$gray-900 !default;
$dark-75:          									$gray-800 !default; // Custom variable
$dark-65:          									$gray-700 !default; // Custom variable
$dark-50:          									$gray-600 !default; // Custom variable
$dark-25:          									$gray-400 !default; // Custom variable

// Text muted
$text-muted:                  						$gray-500 !default; // Custom variable

// Gray colors list
$grays: (
				"100": $gray-100,
				"200": $gray-200,
				"300": $gray-300,
				"400": $gray-400,
				"500": $gray-500,
				"600": $gray-600,
				"700": $gray-700,
				"800": $gray-800,
				"900": $gray-900
) !default; // Custom variable

// Bootstrap standard & extended colors
// Dark colors
$dark:          									$dark !default; // Bootstrap variable
$dark-hover:          								darken($dark, 3%) !default; // Custom variable
$dark-light:          							    $gray-400 !default; // Custom variable
$dark-inverse:         								$white !default; // Custom variable

// Primary colors
$primary:       									kt-get($kt-state-colors, primary, base) !default; // Bootstrap variable
$primary-hover:    									#187DE4 !default; // Custom variable
$primary-light:    									#E1F0FF !default; // Custom variable
$primary-light-light:    							#EEF9FE !default; // Custom variable
$primary-inverse:  									$white !default;  // Custom variable
$primary-dark:  									#054C9E !default;  // Custom variable

// Secondary colors
$secondary:     									kt-get($kt-state-colors, secondary, base) !default; // Bootstrap variable
$secondary-hover:  									darken(kt-get($kt-state-colors, secondary, base), 4%) !default; // Custom variable
$secondary-light:  									$gray-200 !default; // Custom variable
$secondary-inverse:									$dark-75 !default; // Custom variable

// Success colors
$success:       									kt-get($kt-state-colors, success, base) !default; // Bootstrap variable
$success-hover:    									#0BB7AF !default; // Custom variable
$success-light:    									#C9F7F5 !default; // Custom variable
$success-inverse:  									$white !default; // Custom variable

// Inco colors
$info:         		 								kt-get($kt-state-colors, info, base) !default; // Bootstrap variable
$info-hover:   		 								#7337EE !default; // Custom variable
$info-light:   		 								#EEE5FF !default; // Custom variable
$info-inverse: 		 								$white !default; // Custom variable

// Warning colors
$warning:       									kt-get($kt-state-colors, warning, base) !default; // Bootstrap variable
$warning-hover:    									#EE9D01 !default; // Custom variable
$warning-light:    									#FFF4DE !default; // Custom variable
$warning-inverse:  									$white !default; // Custom variable
$warning-gold:  									#C08000 !default; // Custom variable

// Danger colors
$danger:        									kt-get($kt-state-colors, danger, base) !default; // Bootstrap variable
$danger-hover:     									#EE2D41 !default; // Custom variable
$danger-light:     									#FFE2E5 !default; // Custom variable
$danger-lite:     									#FA347B !default; // Custom variable
$danger-lite-light:     							#FFEFEF !default; // Custom variable
$danger-inverse:   									$white !default; // Custom variable

// Light colors
$light:         									kt-get($kt-state-colors, light, base) !default; // Bootstrap variable
$light-hover:         								$gray-300 !default; // Custom variable
$light-light:         								$gray-100 !default; // Custom variable
$light-inverse:        								$gray-600 !default; // Custom variable

// White colors
$white:    											$white !default; // Bootstrap variable
$white-hover:    									$gray-100 !default; // Custom variable
$white-light:    									$white !default;  // Custom variable
$white-inverse:    									$dark-75 !default; // Custom variable

// Yellow colors
$yellow: 											#FFC107;

// Orange colors
$light-orange: 										#F9D9B6;
$peach:                                             #FFF7F2;
$orange: 											#FF8800;
$orange-light:                                      #F9D9B6;
$dark-orange:										#FF7222;
$dark-orange-light:									#F9D9B6;
// Blue colors

$dark-blue: 												#000EE3;
$light-blue: 												#F4F6FD;

// Purple colors
$purple: 											#8950fc;
$purple-light: 										#5867dd;

// Green colors
$green: 											#0AAC68;
$green-inverse: 									$white;
$turquoise:                                         #12BEA4;

// Pink colors
$pink:                                            #FF6E6E;

// Initial
$initial:                                         #525672;

// Bootstrap theme colors
$theme-colors: (
				"white":         		$white,  // custom color type
				"primary":       		$primary,
				"secondary":     		$secondary,
				"success":       		$success,
				"info":          		$info,
				"warning":       		$warning,
				"danger":        		$danger,
				"light":         		$light,
				"dark":          		$dark,
				"closed": 	     		$info-hover,
				"yellow":	     		$yellow,
				"orange": 	     		$orange,
				"dark-orange": 	     	$dark-orange,
				"dark-orange-light": 	     	$dark-orange-light,
				"purple": 				$purple,
				"purple-light": 		$purple-light,
				"dark-blue": 			$dark-blue,
				"light-blue": 			$light-blue,
				"lighten":   	 		$gray-200,
				"gray-light":    		$gray-400,
                "light-grey-secondary": $light-grey-secondary,
				"dark-light":    		$light-gray,
				"primary-dark":  		$primary-dark,
				"success-light": 		$success-light,
				"danger-light":  		$danger-light,
				"danger-lite":   		$danger-lite,
				"danger-lite-light":   	$danger-lite-light,
				"warning-gold":  	  	$warning-gold,
                "initial":              $initial,
				"green": 				$green,
				"turquoise": 			$turquoise,
                "pink":                 $pink,
                "light-orange":         $light-orange,
                "peach":                $peach
) !default;

// Extended hover colors for Bootstrap theme colors
$theme-hover-colors: (
				"white":      $white-hover,   // custom color type
				"primary":    $primary-hover,
				"secondary":  $secondary-hover,
				"success":    $success-hover,
				"info":       $info-hover,
				"warning":    $warning-hover,
				"danger":     $danger-hover,
				"light":      $light-hover,
				"dark":       $dark-hover
) !default;  // Custom variable

// Extended inverse colors for Bootstrap theme colors
$theme-inverse-colors: (
				"white":      $white-inverse,
				"primary":    $primary-inverse,
				"secondary":  $secondary-inverse,
				"success":    $success-inverse,
				"info":       $info-inverse,
				"warning":    $warning-inverse,
				"danger":     $danger-inverse,
				"light":      $light-inverse,
				"dark":       $dark-inverse,
				"green":      $green-inverse,
				"turquoise":      $green-inverse
) !default;  // Custom variable

// Extended outline colors for Bootstrap theme colors
$theme-outline-inverse-colors: (
				"white":      $white,
				"primary":    $primary,
				"secondary":  $dark-75,
				"success":    $success,
				"info":       $info,
				"warning":    $warning,
				"danger":     $danger,
				"light":      $dark-75,
				"dark":       $dark
) !default;  // Custom variable

// Extended light colors for Bootstrap theme colors
$theme-light-colors: (
				"white":      $white-light,
				"primary":    $primary-light,
				"secondary":  $secondary-light,
				"success":    $success-light,
				"info":       $info-light,
				"warning":    $warning-light,
				"danger":     $danger-light,
				"danger-lite":$danger-lite-light,
				"light":      $light-light,
				"dark":       $dark-light,
				"closed":     $info-light,
                "orange":     $orange,
                "dark-orange":     $dark-orange-light,
) !default;  // Custom variable

// Extended shadow colors for Bootstrap theme colors
$theme-shadow-colors: (
				"white":      $dark,
				"primary":    $primary,
				"secondary":  $dark,
				"success":    $success,
				"info":       $info,
				"warning":    $warning,
				"danger":     $danger,
				"light":      $dark,
				"dark":       $dark
) !default;  // Custom variable

// Extended theme text colors
$theme-text-colors: (
				"white":      $white,
				"primary":    $primary,
				"secondary":  $secondary,
				"success":    $success,
				"info":       $info,
				"warning":    $warning,
				"danger":     $danger,
				"light":      $light,
				"dark":       $dark,
				"dark-blue":  $dark-blue,
				"dark-75":    $dark-75,
				"dark-65":    $dark-65,
				"dark-50":    $dark-50,
				"dark-25":    $dark-25,
				"muted":      $text-muted,
                "initial":    $initial,
) !default;  // Custom variable

// Social Network Colors Used With Boostrap Buttons(see: https://primarycolors.net/)
$social-colors: (
				facebook: (
								base: #3b5998,
								inverse: #ffffff,
								light: rgba(#3b5998, 0.1),
								hover: darken(#3b5998, 7.5%)
				),
				google: (
								base: #dd4b39,
								inverse: #ffffff,
								light: rgba(#dd4b39, 0.1),
								hover: darken(#dd4b39, 7.5%)
				),
				twitter: (
								base: #1da1f2,
								inverse: #ffffff,
								light: rgba(#1da1f2, 0.1),
								hover: darken(#1da1f2, 7.5%)
				),
				instagram: (
								base: #e1306c,
								inverse: #ffffff,
								light: rgba(#e1306c, 0.1),
								hover: darken(#e1306c, 7.5%)
				),
				youtube: (
								base: #ff0000,
								inverse: #ffffff,
								light: rgba(#ff0000, 0.1),
								hover: darken(#ff0000, 7.5%)
				),
				linkedin: (
								base: #0077b5,
								inverse: #ffffff,
								light: rgba(#0077b5, 0.1),
								hover: darken(#0077b5, 7.5%)
				),
				skype: (
								base: #00aff0,
								inverse: #ffffff,
								light: rgba(#00aff0, 0.1),
								hover: darken(#00aff0, 7.5%)
				)
) !default; // Custom variable

// Extended custom spacing for Bootstrap
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.
$spacer: 1rem !default;
// stylelint-disable-next-line scss/dollar-variable-default
$spacers: (
				0: 0,
				1: ($spacer * .25),  	// 3.5px
				2: ($spacer * .5),   	// 7px;
				3: ($spacer * .75),  	// 10.5px
				4: ($spacer * 1),    	// 14px
				5: ($spacer * 1.25), 	// 17.5px
				6: ($spacer * 1.5),  	// 21px
				7: ($spacer * 1.75), 	// 24.5px
				8: ($spacer * 2),    	// 28px
				9: ($spacer * 2.25), 	// 31.5px
				10: ($spacer * 2.5), 	// 35px
				11: ($spacer * 2.75),	// 38.5px
				12: ($spacer * 3),   	// 42px
				13: ($spacer * 3.25),	// 45.5px
				14: ($spacer * 3.5), 	// 49px
				15: ($spacer * 3.75), 	// 52.5px
				16: ($spacer * 4), 		// 55px
				17: ($spacer * 4.25), 	// 58.5px
				18: ($spacer * 4.5), 	// 62px
				19: ($spacer * 4.75), 	// 65.5px
				20: ($spacer * 5), 		// 69px
				21: ($spacer * 5.25),	// 73.5px
				22: ($spacer * 5.5), 	// 77px
				23: ($spacer * 5.75), 	// 80.5px
				24: ($spacer * 6), 		// 84px
				25: ($spacer * 6.25), 	// 87.5px
				26: ($spacer * 6.5), 	// 91px
				27: ($spacer * 6.75), 	// 94.5px
				28: ($spacer * 7), 		// 99px
				29: ($spacer * 7.25),   // 102.5px
				30: ($spacer * 7.5), 	// 106px
				31: ($spacer * 7.75), 	// 109.5px
				32: ($spacer * 8), 		// 113px
				33: ($spacer * 8.25), 	// 116.5px
				34: ($spacer * 8.5), 	// 120px
				35: ($spacer * 8.75), 	// 123.5px
				36: ($spacer * 9), 		// 127px
				37: ($spacer * 9.25),    // 130.5px
				48: ($spacer * 9.5), 	// 134px
				39: ($spacer * 9.75), 	// 137.5px
				40: ($spacer * 10), 	// 140px
);

// Components
//
// Define common padding and border radius sizes and more.
$line-height-xl:									1.8 !default;
$line-height-lg:              						1.5 !default;
$line-height-sm:              						1.35 !default;

$component-light-style-opacity:                 	0.1 !default; // Custom variable
$component-active-color:                            $white !default;
$component-active-bg:                               $primary !default;

// Box shadow
$box-shadow-xs:                                     0 0.25rem 0.5rem 0 rgba($black, 0.05);
$box-shadow-sm:               						0 .1rem 1rem 0.25rem rgba($black, .05) !default;
$box-shadow:                  						0 .5rem 1.5rem 0.5rem rgba($black, .075) !default;
$box-shadow-lg:               						0 1rem 2rem 1rem rgba($black, .1) !default;

// Border Radiues
$border-radius-sm:            						.28rem !default;
$border-radius:               						.42rem !default;
$border-radius-lg:            						.85rem !default;
$border-radius-xl:                                  1.25rem !default; // Custom variable

// Border settings
$border-color:                                      $gray-200 !default;
$border-width:                                      1px !default;

// Hover background color
$hover-bg:                                          $gray-100 !default; // Custom variable

// Activebackground color
$active-bg:                                         darken($gray-100, 1%) !default; // Custom variable

// Options
//
// Quickly modify global styling by enabling or disabling optional features.
$enable-rounded: 									true !default;
$enable-shadows:                              		true !default;

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.
$container-max-widths: (
				sm: 540px,
				md: 720px,
				lg: 960px,
				xl: 1140px,
				xxl: 1340px
) !default;


// Grid columns
//
// Set the number of columns and specify the width of the gutters.
$grid-columns:                						12 !default;
$grid-gutter-width:           						25px !default;
$grid-divider-bg:                                   $gray-200 !default; // Custom variable


// Body
// Settings for the `<body>` element.
$body-bg:                   						$white !default;
$body-color:                						$dark-75 !default;

// Typography
//
// Font, line-height, and color for body text, headings, and more.

// Font family
$font-family-base:            						Poppins, Helvetica, "sans-serif" !default;

$font-size-base:              						1rem !default; // Assumes the browser default, typically `13px`
$font-size-lg:               	 					$font-size-base * 1.08 !default;   // 14.04px
$font-size-sm:                						$font-size-base * .925 !default;   // 12.025px
$font-size-xs:                						$font-size-base * .8 !default;     // 10.4px
$font-size-xl:                                      $font-size-base * 2.5 !default;

$h1-font-size:                                      $font-size-base * 2 !default;      // 26px
$h2-font-size:                                      $font-size-base * 1.75 !default;   // 22.75px
$h3-font-size:                                      $font-size-base * 1.5 !default;    // 19.5px
$h4-font-size:                                      $font-size-base * 1.35 !default;   // 17.55px
$h5-font-size:                                      $font-size-base * 1.25 !default;   // 16.25px
$h6-font-size:                                      $font-size-base * 1.175 !default;  // 15.275px

$headings-margin-bottom:                            calc($spacer / 2) !default;
$headings-font-family:                              null !default;
$headings-font-weight:                              500 !default;
$headings-line-height:                              1.2 !default;
$headings-color:                                    null !default;

$display1-size:                                     5.5rem !default;  // 71.5px
$display2-size:                                     4.5rem !default;  // 58.5px
$display3-size:                                     3.5rem !default;  // 45.5px
$display4-size:                                     2.5rem !default;  // 32.5px
$display5-size:                                     2.25rem !default; // 29.5px

// Font Sizes
$font-sizes: (
				font-size-h1: $h1-font-size,
				font-size-h2: $h2-font-size,
				font-size-h3: $h3-font-size,
				font-size-h4: $h4-font-size,
				font-size-h5: $h5-font-size,
				font-size-h6: $h6-font-size,
				display1: $display1-size,
				display2: $display2-size,
				display3: $display3-size,
				display4: $display4-size,
				display5: $display5-size
) !default;

$display1-weight:                                   300 !default;
$display2-weight:                                   300 !default;
$display3-weight:                                   300 !default;
$display4-weight:                                   300 !default;
$display-line-height:                               $headings-line-height !default;

$lead-font-size:                                    $font-size-base * 1.25 !default;
$lead-font-weight:                                  300 !default;
$small-font-size:                                   80% !default;

// Font Weight
$font-weight-lighter:         						lighter !default;
$font-weight-light:           						300 !default;
$font-weight-normal:          						400 !default;
$font-weight-semibold:          					500 !default;
$font-weight-bold:            						600 !default;
$font-weight-boldest:          						700 !default;

$line-height-base:                                  1.5 !default;


// Transition
$transition:                                  		all 0.15s ease !default; // Custom variable
$transition-link:                                   color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease !default; // Custom variable
$transition-input:                                  color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease !default; // Custom variable


// Links
//
// Style anchor elements.
$link-color:                                        $primary !default;
$link-decoration:                                   none !default;
$link-hover-color:                                  darken($primary, 15%) !default;
$link-hover-decoration:                             underline !default;
// Darken percentage for links with `.text-*` class (e.g. `.text-success`)
$emphasized-link-hover-darken-percentage:           15% !default;


// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.
$input-btn-padding-y:                               .65rem !default;
$input-btn-padding-x:                               1rem !default;
$input-btn-font-family:                             null !default;
$input-btn-font-size:                               $font-size-base !default;
$input-btn-line-height:                             $line-height-base !default;

$input-btn-focus-width:                             .2rem !default;
$input-btn-focus-color:                             rgba($component-active-bg, .25) !default;
$input-btn-focus-box-shadow:                        none !default;

$input-btn-padding-y-sm:                            .55rem !default;
$input-btn-padding-x-sm:                            .75rem !default;
$input-btn-font-size-sm:                            $font-size-sm !default;
$input-btn-line-height-sm:                          $line-height-sm !default;

$input-btn-padding-y-lg:                            0.825rem !default;
$input-btn-padding-x-lg:                            1.42rem !default;
$input-btn-font-size-lg:                            $font-size-lg !default;
$input-btn-line-height-lg:                          $line-height-lg !default;

$input-btn-border-width:                            $border-width !default;


// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.
$btn-disabled-opacity:        						.6 !default;
$btn-transition:              						color .3s ease-in-out, background-color .3s ease-in-out, border-color .3s ease-in-out, box-shadow .3s ease-in-out !default;// Custom variable
$btn-box-shadow:                                    none !default;
$btn-focus-box-shadow:                              none !default;
$btn-active-box-shadow:                             none !default;
$btn-font-weight:             						normal !default;
$btn-secondary-color:								$dark-75 !default;
$btn-secondary-hover-bg-color:						$gray-100 !default;
$btn-icon-size-xs:                                  24px !default; //custom parameter

// Allows for customizing button radius independently from global border radius
$btn-border-radius:                                 $border-radius !default;
$btn-border-radius-lg:                              $border-radius !default;
$btn-border-radius-sm:                              $border-radius !default;

$input-plaintext-color:                 			$dark-75 !default;
$input-placeholder-color:               			$text-muted !default;
$input-color:                           			$dark-75 !default;
$input-border-color:								$gray-300 !default;
$input-border-width:                                $input-btn-border-width !default;
$input-box-shadow:                                  none !default;
$input-focus-border-color:							lighten($primary, 10%);
$input-focus-box-shadow:                            none !default;
$input-height-border:                               $input-border-width * 2 !default;

$input-bg:                                          $white !default;
$input-disabled-bg:                     			$gray-100 !default;
$input-readonly-bg:                     			$white !default; // Custom variable
$input-group-icon-color:                     		$dark-75 !default;
$input-group-addon-color:               			$dark-75 !default;
$input-group-addon-bg:                  			$gray-100 !default;

$input-border-radius:                   			$border-radius !default;
$input-border-radius-lg:                			$border-radius !default;
$input-border-radius-sm:                			$border-radius-sm !default;

$input-solid-bg:                                    $gray-100; // Custom variable
$input-solid-bg-focus:                              $gray-200; // Custom variable
$input-solid-placeholder-color:                     $input-placeholder-color;  // Custom variable
$input-solid-color:                              	$dark-75; // Custom variable

$custom-control-indicator-checked-box-shadow:       none !default;
$custom-control-indicator-active-box-shadow:        none !default;

// Forms
$form-group-margin-bottom:                          1.75rem !default;
$form-label-font-size:                              1rem !default; // Custom variable
$form-label-font-weight:                            400 !default; // Custom variable
$form-feedback-font-size:                           0.9rem !default; // Custom variable
$form-feedback-font-weight:                         400 !default; // Custom variable
$form-text-font-size:                               0.9rem !default; // Custom variable
$form-text-font-weight:                             400 !default; // Custom variable
$form-validation-input-shadow:                      false;  // Custom variable

// Table
$table-bg:                      					transparent !default;
$table-accent-bg:               					$gray-200 !default;
$table-hover-bg:                					$gray-300 !default;
$table-active-bg:               					$table-hover-bg !default;

$table-border-color:            					$gray-200 !default;
$table-head-bg:                 					$gray-100 !default;
$table-head-color:              					$dark-75 !default;
$table-head-font-size:             					1rem !default; // Custom variable
$table-head-font-weight:           					600 !default; // Custom variable

$table-dark-color:            						$white !default;
$table-dark-bg:               						$dark !default;
$table-dark-accent-bg:        						rgba($white, .05) !default;
$table-dark-hover-color:      						$table-dark-color !default;
$table-dark-hover-bg:        						rgba($white, .075) !default;
$table-dark-border-color:     						lighten($table-dark-bg, 7.5%) !default;

$table-head-bg:                                     $gray-100 !default; // Custom variable
$table-compact-head-font-size:                      0.9rem !default; // Custom variable
$table-compact-head-color:                          $text-muted !default; // Custom variable
$table-compact-head-text-transform:                 uppercase !default; // Custom variable
$table-compact-head-font-weight:                    600 !default; // Custom variable
$table-compact-head-font-weight:                    600 !default; // Custom variable
$table-compact-head-letter-spacing:                 0.1rem !default; // Custom variable

// Card
$card-box-shadow: 									0px 0px 30px 0px rgba(82,63,105,0.05) !default; // card custom shadow
$card-border-color:                 				$gray-200 !default;
$card-cap-bg:                       				$white !default;
$card-spacer-y:                     				2rem !default;
$card-spacer-x:                    					2.25rem !default;
$card-border-radius:                				$border-radius !default;

$card-header-spacer-y:                              0.5rem !default; // Custom variable
$card-header-height: 								70px !default; // Custom variable
$card-sticky-header-bg:   						    $white !default; // Custom variable
$card-sticky-header-height: 						70px !default; // Custom variable
$card-sticky-zindex:                                101  !default; // Custom variable
$card-sticky-shadow:                                0px 1px 15px 1px rgba(69, 65, 78, 0.1) !default; // Custom variable

// List group
$list-group-border-color:           				$gray-200 !default;
$list-group-hover-bg:               				$gray-200 !default;


// Modals

// Padding applied to the modal body
$modal-inner-padding:              					1.75rem !default;

// Margin between elements in footer, must be lower than or equal to 2 * $modal-inner-padding
$modal-footer-margin-between:       				.5rem !default;

$modal-dialog-margin:               				.5rem !default;
$modal-dialog-margin-y-sm-up:       				1.75rem !default;

$modal-content-color:               				null !default;
$modal-content-bg:                  				$white !default;
$modal-content-border-color:        				rgba($black, .2) !default;
$modal-content-border-width:        				0 !default;
$modal-content-border-radius:       				$border-radius !default;
$modal-content-inner-border-radius: 				$border-radius !default;

$modal-content-box-shadow:       				    0 .25rem .5rem rgba($black, .05) !default;
$modal-content-box-shadow-xs:       				0 .25rem .5rem rgba($black, .1) !default;
$modal-content-box-shadow-sm-up:   					0 .5rem 1rem rgba($black, .1) !default;

$zindex-modal:                                      1050 !default;
$modal-backdrop-bg:                		 			$black !default;
$modal-backdrop-opacity:            				.2 !default;
$modal-header-border-color:         				$border-color !default;
$modal-footer-border-color:         			 	$border-color !default;
$modal-header-border-width:         				1px !default;
$modal-footer-border-width:         				1px !default;
$modal-header-padding-y:            				1.5rem !default;
$modal-header-padding-x:            				1.75rem !default;
$modal-header-padding:              				$modal-header-padding-y $modal-header-padding-x !default; // Keep this for backwards compatibility

$modal-xl:                         		 			1140px !default;
$modal-lg:                          				900px !default;
$modal-md:                          				700px !default;
$modal-sm:                          				300px !default;


// Pagination
$pagination-color:                  				$primary !default;
$pagination-border-color:           				$gray-300 !default;
$pagination-hover-bg:              	 				$gray-200 !default;
$pagination-hover-border-color:     				$gray-300 !default;
$pagination-disabled-border-color:  				$gray-200 !default;


// Dropdowns
// Dropdown menu container and contents.
$dropdown-box-shadow:								0px 0px 50px 0px rgba(82,63,105, 0.15) !default;
$zindex-dropdown:                   				98 !default;
$dropdown-border-radius:                            $border-radius !default;
$dropdown-border-width:                             0 !default;
$dropdown-item-padding-y:                           .75rem !default;
$dropdown-item-padding-x:                           1.25rem !default;
$dropdown-divider-bg:                               $gray-200 !default;
$dropdown-menu-widths: (
				sm: 175px,
				md: 250px,
				lg: 350px,
				xl: 400px,
				xxl: 600px
); // Custom variable


// Popovers
$popover-font-size:                 				0.9rem !default;
$popover-max-width:                 				276px !default;
$popover-border-width:              				1px !default;
$popover-border-color:              				#ffffff !default;
$popover-border-radius:             				$border-radius !default;
$popover-box-shadow:                				0px 0px 20px 0px rgba(0,0,0,0.15) !default;
$popover-header-bg:                 				#ffffff !default;
$popover-header-color:              				$dark !default;
$popover-header-font-weight:           				500 !default;  // Custom variable
$popover-header-font-size:             				1rem !default;  // Custom variable
$popover-header-padding-y:          				1rem !default;
$popover-header-padding-x:          				1.25rem !default;
$popover-header-border-color:                       $gray-200 !default;  // Custom variable
$popover-body-color:                				$body-color !default;


// Tooltips
$tooltip-font-size:                 				0.9rem !default;
$tooltip-max-width:                 				200px !default;
$tooltip-color:                     				$dark-75 !default;
$tooltip-bg:                        				#ffffff !default;
$tooltip-border-radius:             				3px !default;
$tooltip-opacity:                   				1 !default;
$tooltip-box-shadow:                				$popover-box-shadow !default; // Custom variable
$tooltip-padding-y:                 				.75rem !default;
$tooltip-padding-x:                 				1rem !default;
$tooltip-margin:                    				0 !default;

// Custom Toasts
$toast-zindex:										$zindex-dropdown + 100 !default;
$toast-spacing:										2rem  !default;
$toast-width: 										350px !default;
$toast-box-shadow:									$dropdown-box-shadow !default;
$toast-header-color: 								$dark-75 !default;
$toast-header-background-color: 					#fff !default;
$toast-header-border-color:							$gray-200 !default;


// Progress bars
$progress-height:                                   1rem !default;
$progress-height-xs:                                0.5rem !default; // Custom variable
$progress-height-sm:                                0.75rem !default; // Custom variable
$progress-height-lg:                                1.5rem !default; // Custom variable
$progress-bg:                                       $gray-200 !default;
$progress-border-radius:                            $border-radius !default;
$progress-box-shadow:                               none !default;
$progress-bar-color:                                $white !default;
$progress-bar-bg:                                   $primary !default;
$progress-bar-animation-timing:                     1s linear infinite !default;
$progress-bar-transition:                           width .6s ease !default;

// Badges
$badge-padding-y:                   				.5em !default;
$badge-padding-x:                   				.75em !default;
$badge-font-size:                   				85% !default;


// Code
$code-bg:                                           $gray-100 !default; // Custom variable
$code-padding:                                      0.15rem 0.5rem !default; // Custom variable


// Modals
// Padding applied to the modal body
$modal-inner-padding:                               1rem !default;
$modal-dialog-margin:                               .5rem !default;
$modal-dialog-margin-y-sm-up:                       1.75rem !default;
$modal-title-color:                                 $dark !default; // Custom variable
$modal-title-font-weight:                           500 !default; // Custom variable
$modal-title-font-size:                             1.3rem !default; // Custom variable
$modal-title-small-color:                           $text-muted !default; // Custom variable
$modal-title-small-font-weight:                     400 !default; // Custom variable
$modal-title-small-font-size:                       0.9rem !default; // Custom variable
$modal-title-close-font-size:                       1rem !default; // Custom variable
$modal-title-close-color:                           $text-muted !default; // Custom variable
$modal-title-close-hover-color:                     $primary !default; // Custom variable

$modal-content-color:                               null !default;
$modal-content-bg:                                  $white !default;
$modal-content-border-color:                        rgba($black, .2) !default;
$modal-content-border-radius:                       $border-radius-lg !default;
$modal-content-box-shadow-xs:                       0 .25rem .5rem rgba($black, .5) !default;
$modal-content-box-shadow-sm-up:                    0 .5rem 1rem rgba($black, .5) !default;

$modal-backdrop-bg:                                 $black !default;
$modal-backdrop-opacity:                            .5 !default;
$modal-header-border-color:                         $border-color !default;
$modal-footer-border-color:                         $modal-header-border-color !default;

$modal-xl:                                          1140px !default;
$modal-lg:                                          800px !default;
$modal-md:                                          500px !default;
$modal-sm:                                          300px !default;


// Pagination
$pagination-padding-y:                              .5rem !default;
$pagination-padding-x:                              .75rem !default;
$pagination-padding-y-sm:                           .25rem !default;
$pagination-padding-x-sm:                           .5rem !default;
$pagination-padding-y-lg:                           .75rem !default;
$pagination-padding-x-lg:                           1.5rem !default;
$pagination-line-height:                            1.25 !default;

$pagination-color:                                  $link-color !default;
$pagination-bg:                                     $white !default;
$pagination-border-color:                           $gray-300 !default;

$pagination-focus-box-shadow:                       none !default;
$pagination-focus-outline:                          0 !default;

$pagination-hover-color:                            $link-hover-color !default;
$pagination-hover-bg:                               $gray-200 !default;
$pagination-hover-border-color:                     $gray-300 !default;

$pagination-disabled-color:                         $gray-600 !default;
$pagination-disabled-bg:                            $white !default;
$pagination-disabled-border-color:                  $gray-300 !default;

// Alerts
//
// Define alert colors, border radius, and padding.
$alert-box-shadow:                					$card-box-shadow !default; // Custom variable


// Navs

$nav-link-px:                                       1.5rem;
$nav-link-py:                                       0.75rem;
$nav-btn-px:                                        1rem;
$nav-section-py:                                    0.5rem;
$nav-elem-space:                                    0.25rem;

$nav-link-bg-hover:                                 $gray-100;
$nav-link-bg-active:                                $gray-200;

$nav-header-py:                                     1rem;
$nav-footer-py:                                     1rem;

$nav-font-size:                                     1rem;
$nav-icon-size:                                     1.25rem;
$nav-icon-width:                                    2rem;

//ORIGINAL

$enable-rounded: $kt-rounded; // pass rounded corners setting to bootstrap SASS

// Fonts
$font-family-base:									kt-get($kt-font-families, regular);



// Color System
$primary:       									kt-get($kt-state-colors, primary, base) !default;
$secondary:     									#e1e1ef !default;
$success:       									kt-get($kt-state-colors, success, base) !default;
$info:          									kt-get($kt-state-colors, info, base) !default;
$warning:       									kt-get($kt-state-colors, warning, base) !default;
$danger:        									kt-get($kt-state-colors, danger, base) !default;
$light:         									#f8f9fa !default;
$dark:          									#343a40 !default;

$theme-colors: map-merge((
				"primary":    $primary,
				"secondary":  $secondary,
				"success":    $success,
				"info":       $info,
				"warning":    $warning,
				"danger":     $danger,
				"light":      $light,
				"dark":       $dark,
                "dark-blue":  $dark-blue,
), $theme-colors);

// Extended inverse colors for Bootstrap theme colors
$theme-inverse-colors: (
				"white":      $white-inverse,
				"primary":    $primary-inverse,
				"secondary":  $secondary-inverse,
				"success":    $success-inverse,
				"info":       $info-inverse,
				"warning":    $warning-inverse,
				"danger":     $danger-inverse,
				"light":      $light-inverse,
				"dark":       $dark-inverse,
) !default;  // Custom variable

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: 									$kt-media-breakpoints !default;

// Border Radiues
$border-radius:               						.25rem !default;
$border-radius-lg:            						.3rem !default;
$border-radius-sm:            						.2rem !default;

// Border Colors
$border-primary-color:                              #E6E6E6;

// Form & Button Controls
$btn-font-weight:             						normal !default;
$input-plaintext-color:                 			kt-base-color(label, 3) !default;
$input-placeholder-color:               			kt-base-color(label, 2) !default;

$btn-secondary-color:								kt-base-color(label, 3) !default;
$btn-secondary-hover-bg-color:						#f4f5f8 !default;
$input-border-radius:                   			$kt-border-radius !default;
$input-btn-padding-y:       						.65rem;
$input-btn-padding-x:       						1rem;
$input-btn-line-height:     						1.5;

$input-btn-padding-y-sm:    						.5rem;
$input-btn-padding-x-sm:    						1rem;
$input-btn-line-height-sm:  						1.5;

$input-btn-padding-y-lg:    						1.15rem;
$input-btn-padding-x-lg:    						1.65rem;
$input-btn-line-height-lg:  						1.5;

$input-border-color:								kt-base-color(grey, 3) !default;
$input-focus-border-color:							lighten(kt-state-color(brand, base), 12%);
$input-disabled-bg:                     			kt-base-color(grey, 1) !default;
$input-readonly-bg:                     			#ffffff !default;
$input-group-icon-color:                     		kt-base-color(label, 2) !default;

$input-group-addon-color:               			kt-base-color(label, 2) !default;
$input-group-addon-bg:                  			kt-base-color(grey, 1) !default;

// Button elevate shadows
$btn-elevate-shadow-1:								0px 0px 11px 0px rgba(56,36,99,0.08);
$btn-elevate-shadow-1-hover:						0px 0px 22px 0px rgba(56,36,99,0.11);
$btn-elevate-shadow-2:								0px 0px 11px 0px rgba(56,36,99,0.10);
$btn-elevate-shadow-2-hover:						0px 0px 22px 0px rgba(56,36,99,0.13);
$btn-elevate-shadow-3:								0px 0px 11px 0px rgba(56,36,99,0.12);
$btn-elevate-shadow-3-hover:						0px 0px 22px 0px rgba(56,36,99,0.15);


// Table
$table-bg:                      					transparent !default;
$table-accent-bg:               					lighten(#f4f5f8, 1%);
$table-hover-bg:                					lighten(#f4f5f8, 2%);
$table-active-bg:               					kt-state-color(primary, base);

$table-border-color:            					kt-base-color(grey, 2);

$table-head-bg:                 					kt-base-color(grey, 2);
$table-head-color:              					kt-base-color(label, 3);


// Card
$card-border-color:                 				kt-base-color(grey, 2) !default;
$card-cap-bg:                       				kt-base-color(grey, 1) !default;


// List group
$list-group-border-color:           				kt-base-color(grey, 2) !default;
$list-group-hover-bg:               				kt-base-color(grey, 2) !default;


// Modal
$modal-inner-padding:								1.25rem !default;
$modal-header-padding:								1.25rem !default;

$modal-content-border-color:        				kt-base-color(grey, 2) !default;
$modal-content-box-shadow-xs:       				0 .25rem .5rem rgba(kt-base-color(grey, 2), .5) !default;
$modal-content-box-shadow-sm-up:   	 				0 .5rem 1rem rgba(kt-base-color(grey, 2), .5) !default;

$modal-header-border-color:         				kt-base-color(grey, 2) !default;
$modal-footer-border-color:         				kt-base-color(grey, 2) !default;


// Pagination
$pagination-color:                  				kt-state-color(brand, base) !default;
$pagination-border-color:           				kt-base-color(grey, 2) !default;

$pagination-hover-bg:              	 				kt-base-color(grey, 2) !default;
$pagination-hover-border-color:     				kt-base-color(grey, 2) !default;

$pagination-disabled-border-color:  				kt-base-color(grey, 2) !default;



// Popovers
$popover-font-size:                 				0.9rem !default;
$popover-max-width:                 				276px !default;
$popover-border-width:              				1px !default;
$popover-border-color:              				#ffffff !default;
$popover-border-radius:             				3px !default;
$popover-box-shadow:                				0px 0px 20px 0px rgba(0,0,0,0.15) !default;

$popover-header-bg:                 				#ffffff !default;
$popover-header-color:              				kt-base-color(label, 3) !default;
$popover-header-padding-y:          				1rem !default;
$popover-header-padding-x:          				1.25rem !default;

$popover-body-color:                				kt-base-color(label, 2) !default;



// Tooltips
$tooltip-font-size:                 				0.9rem !default;
$tooltip-max-width:                 				200px !default;
$tooltip-color:                     				kt-base-color(label, 3) !default;
$tooltip-bg:                        				#ffffff !default;
$tooltip-border-radius:             				3px !default;
$tooltip-opacity:                   				1 !default;
$tooltip-padding-y:                 				.75rem !default;
$tooltip-padding-x:                 				1rem !default;
$tooltip-margin:                    				0 !default;

// Custom Toasts
$toast-zindex:										$kt-dropdown-zindex + 100 !default;
$toast-spacing:										2rem  !default;
$toast-width: 										350px !default;
$toast-box-shadow:									$kt-dropdown-shadow !default;

$toast-header-color: 								kt-base-color(label, 3) !default;
$toast-header-background-color: 					#fff !default;
$toast-header-border-color:							kt-base-color(grey, 2) !default;



// Progress bars
$progress-bg:                       				kt-get-color() !default;



// Dropdown
$dropdown-box-shadow:								$kt-dropdown-shadow !default;
$zindex-dropdown:                   				$kt-dropdown-zindex !default;



// Text muted
$text-muted:                  						kt-base-color(label, 2) !default;



// Badges
$badge-padding-y:                   				.5em !default;
$badge-padding-x:                   				.75em !default;
$badge-font-size:                   				85% !default;


// Grid columns
$grid-gutter-width:           						20px !default;


// Dropdowns
//
// Dropdown menu container and contents.
$dropdown-link-color:               				kt-base-color(label, 2) !default;
$dropdown-link-hover-color:         				kt-base-color(label, 3) !default;
$dropdown-link-hover-bg:            				kt-base-color(grey, 1) !default;

$dropdown-link-active-color:        				kt-brand-color(inverse) !default;
$dropdown-link-active-bg:           				kt-brand-color() !default;

$dropdown-link-disabled-color:      				kt-base-color(label, 1) !default;

$dropdown-header-color:             				kt-base-color(label, 2) !default;

//
// Custom Components Config
// To override any custom variable safely you can use _variables.demo.scss
//

// Base icons(See: plugins/keenthemes-icons/font/ki.css)
$ki-types: (
                arrowDown:  '\f109',
                arrowUp:    '\f10e',
                arrowBack:  '\f106',
                arrowNext:  '\f105',
                boldClose:  '\f113',
                close:      '\f130',
                plus:       '\f10d',
                minus:      '\f10a',
                check:      '\f108',
                hide:       '\f115',
                code:       '\f116',
                copy:       '\f117'
) !default;

// Heights & Widths
$heights-and-widths: (
                100: 100%,
                50: 50%,
                auto: auto,
                1px: 1px,
                2px: 2px,
                3px: 3px,
                4px: 4px,
                5px: 5px,
                10px: 10px,
                15px: 15px,
                20px: 20px,
                25px: 25px,
                30px: 30px,
                35px: 35px,
                40px: 40px,
                45px: 45px,
                50px: 50px,
                55px: 55px,
                60px: 60px,
                65px: 65px,
                70px: 70px,
                75px: 75px,
                80px: 80px,
                85px: 85px,
                90px: 90px,
                95px: 95px,
                100px: 100px,
                105px: 105px,
                110px: 110px,
                115px: 115px,
                120px: 120px,
                125px: 125px,
                130px: 130px,
                135px: 135px,
                140px: 140px,
                145px: 145px,
                150px: 150px,
                155px: 155px,
                160px: 160px,
                165px: 165px,
                176px: 170px,
                175px: 175px,
                180px: 180px,
                200px: 200px,
                225px: 225px,
                235px: 235px,
                250px: 250px,
                255px: 255px,
                260px: 260px,
                265px: 265px,
                275px: 275px,
                295px: 295px,
                300px: 300px,
                325px: 325px,
                350px: 350px,
                375px: 375px,
                400px: 400px,
                425px: 425px,
                450px: 450px,
                475px: 475px,
                500px: 500px,
                550px: 550px,
                600px: 600px,
                650px: 650px,
                700px: 700px,
                750px: 750px,
                800px: 800px,
                850px: 850px,
                900px: 900px,
                950px: 950px,
                1000px: 1000px,
) !default;

// Icon Sizes
$icon-sizes: (
                ss: 0.5rem,
                xs: 0.65rem,
                sm: 0.75rem,
                nm: 0.9rem,
                ms: 1.15rem,
                md: 1.25rem,
                lg: 1.5rem,
                xl: 1.75rem,
                xxl: 2.25rem,

                1x: 1rem,
                2x: 2rem,
                3x: 3rem,
                4x: 4rem,
                5x: 5rem,
                6x: 6rem,
                7x: 7rem,
                8x: 8rem,
                9x: 9rem,
                10x: 10rem
);

// SVG Icon Sizes
$svg-icon-sizes: (
                sm: 1.25rem,
                md: 1.5rem,
                lg: 1.75rem,
                xl: 2rem,
                xxl: 2.25rem,

                1x: 1rem,
                2x: 2rem,
                3x: 3rem,
                4x: 4rem,
                5x: 5rem,
                6x: 6rem,
                7x: 7rem,
                8x: 8rem,
                9x: 9rem,
                10x: 10rem,

                full: 100%
);

// Symbol Sizes
$symbol-sizes: (
                20: 20px,
                25: 25px,
                30: 30px,
                35: 35px,
                40: 40px,
                45: 45px,
                default: 50px,
                50: 50px,
                55: 55px,
                60: 60px,
                65: 65px,
                70: 70px,
                75: 75px,
                80: 80px,
                85: 85px,
                90: 90px,
                95: 95px,
                100:100px,
                120:120px,
                130:130px,
                140:140px,
                150:150px
);

// Burger Icon
$burger-icon-config: (
                width: 24px,
                height: 24px,
                transition: all 0.3s,
                thickness: 3px,
                space: 5px,
                radius: 3px,
                theme: (
                                default: $text-muted,
                                hover: $primary,
                                active: $primary
                )
) !default;

// Accordion
$accordion-config: (
                card-spacer-x: 1.25rem,
                card-spacer-y: 1rem,
                card-title-font-size: 1.15rem,
                card-icon-font-size: 1.4rem,
                svg-toggle-width: 3rem
) !default;

// Bullet
$bullet-config: (
                border-radius: 2rem,
                hor-width: 10px,
                hor-height: 2px,
                ver-width: 2px,
                ver-height: 10px,
                dot-size: 4px,
                bar-width: 4px
) !default;

// Loader
$loader-config: (
                size: 1.4rem,
                width: 2px,
                size-sm: 1rem,
                width-sm: 1px,
                size-lg: 2rem,
                width-lg: 3px
) !default;

// Spinner
$spinner-config: (
                size:   1.5rem,
                size-sm: 1.25rem,
                size-lg: 2rem
) !default;

// Timeline
$timeline-config: (
                time-width: 60px,
                badge-width: 30px
) !default;

// Timeline 3
$timeline-3-config: (
                media-size: 50px,
                border-width: 2px
) !default;

// Timeline 5
$timeline-5-config: (
                media-size: 35px
) !default;

// Timeline 6
$timeline-6-config: (
                label-width: 135px,
                bar-width: 40px,
                badge-size: 13px,
                bar-border-width: 3px,
                border-color: $white
) !default;

// Navi
$navi-config: (
                link-px: 1.5rem,
                link-py: 0.75rem,

                header-px: 1.5rem,
                header-py: 0.75rem,

                footer-px: 1.5rem,
                footer-py: 0.75rem,

                section-px: 1.5rem,
                section-py: 0.75rem,

                link-elem-space: 0.75rem,

                link-opacity-disabled: 0.6,

                separator: $border-color,
                border: $border-color,

                link-bg-hover: $gray-100,
                link-bg-active: $gray-100,

                link-bullet-bg: $gray-300,
                link-bullet-bg-hover: $primary,
                link-bullet-bg-active: $primary,

                link-text-font-size: 1rem,
                link-text-font-size-lg: 1.1rem,
                link-text-font-size-xl: 1.2rem,

                link-text-color: $dark-75,
                link-text-color-hover: $primary,
                link-text-color-active: $primary,

                link-icon-circle-width: 4rem,
                link-icon-circle-width-lg: 4.5rem,
                link-icon-circle-width-xl: 5rem,
                link-icon-width: 2rem,
                link-icon-width-lg: 3rem,
                link-icon-width-xl: 4rem,

                link-icon-font-size: 1.25rem,
                link-icon-font-size-lg: 1.5rem,
                link-icon-font-size-xl: 1.75rem,

                link-icon-svg-size: 22px,
                link-icon-svg-size-lg: 28px,
                link-icon-svg-size-xl: 32px,

                link-icon-color: $gray-500,
                link-icon-light-color: $gray-400,
                link-icon-color-hover: $primary,
                link-icon-color-active: $primary,

                link-icon-circle-bg: $gray-100,
                link-icon-circle-bg-hover: $gray-200,
                link-icon-circle-bg-active: $gray-200,

                link-arrow-font-size: 0.6rem,
                link-arrow-color: $gray-500,
                link-arrow-color-hover: $primary,
                link-arrow-color-active: $primary,
) !default;

// Checkbox
$checkbox-config: (
                transition: all 0.3s ease,
                itemSpace: 1rem,
                labelSpace: 0.75rem,
                inlineItemSpace: 0.35rem,
                types: (
                                solid: (
                                                theme: (
                                                                base-color: $gray-200
                                                ),
                                                sizes: (
                                                                default: (
                                                                                base: 18px,
                                                                                tick: 10px,
                                                                ),
                                                                lg: (
                                                                                base: 24px,
                                                                                tick: 12px,
                                                                )
                                                )
                                ),
                                outline: (
                                                theme: (
                                                                base-color: $gray-400
                                                ),
                                                sizes: (
                                                                default: (
                                                                                base: 18px,
                                                                                tick:  10px,
                                                                ),
                                                                lg: (
                                                                                base: 24px,
                                                                                tick: 12px,
                                                                )
                                                )
                                )
                )
) !default;

// Radio
$radio-config: (
                transition: all 0.3s ease,
                itemSpace: 1rem,
                inlineItemSpace: 0.35rem,
                labelSpace: 0.75rem,
                types: (
                                solid: (
                                                theme: (
                                                                base-color: $gray-200
                                                ),
                                                sizes: (
                                                                default: (
                                                                                base: 18px,
                                                                                tick:  6px,
                                                                ),
                                                                lg: (
                                                                                base: 24px,
                                                                                tick:  8px,
                                                                )
                                                )
                                ),
                                outline: (
                                                theme: (
                                                                base-color: $gray-400
                                                ),
                                                sizes: (
                                                                default: (
                                                                                base: 18px,
                                                                                tick:  6px,
                                                                ),
                                                                lg: (
                                                                                base: 24px,
                                                                                tick:  8px,
                                                                )
                                                )
                                ),
                                accent: (
                                                borderWidth: 3px,
                                                sizes: (
                                                                default: (
                                                                                base: 24px,
                                                                                tick: 14px,
                                                                ),
                                                                lg: (
                                                                                base: 30px,
                                                                                tick:  10px,
                                                                )
                                                )
                                )
                )
) !default;

// Switch
$switch-config: (
                color: $gray-200,
                tick: $primary
) !default;

// Scrolltop
$scrolltop-config: (
                bottom: (
                                desktop: 40px,
                                tablet-and-mobile: 30px
                ),
                right: (
                                desktop: 20px,
                                tablet-and-mobile: 15px
                ),
                size: (
                                desktop: 36px,
                                tablet-and-mobile: 30px
                )
) !default;

// Sticky Toolbar
$sticky-toolbar-config: (
                z-index: 95
) !default;

// Offcanvas
$offcanvas-config: (
                width: 375px, // Offcanvas panel's width
                zindex: 1001, // Offcanvas panel's z-index
                shadow: 0px 1px 9px -3px rgba(0,0,0, 0.25), // Offcanvas panel's box shadow
                bg-color: #ffffff, // Offcanvas panel's Background color
                overlay-bg-color: rgba(#000000, 0.1) // Offcanvas panel's overlay background color
) !default;

// Datatable
$datatable-config: (
    // Base
                spacer:                     $card-spacer-x,
                bg:                         #ffffff,

    // Scrollbar
                scrollbar-bg:               $gray-200,

    // Head
                head-bg:                    $gray-100,
                head-color:                 $dark,
                head-sorted-color:          $primary,
                head-icon-color:            $primary,
                head-compact-color:         $text-muted,
                head-compact-font-size:     0.9rem,
                head-compact-text-transform:uppercase,
                head-compact-font-weight:   600,
                head-compact-letter-spacing:0.1rem,

    // Body cell
                body-color:                 $dark-75,

    // Cell padding
                cell-padding-x:             1rem,
                cell-padding-y:             1rem,
                head-cell-padding-x:        1rem,
                head-cell-padding-y:        0.75rem,

    // Row colors
                row-border:                 $gray-200,
                row-bg-even:                transparent,
                row-bg-hover:               transparent,
                row-bg-active:              $gray-100,

    // Lock table shadow
                lock-shadow:                0px 10px 17px 5px rgba(#000000, 0.075),

    // Subtable
                subtable-toggle-icon:       $primary,
                subtable-expanded-bg:       $gray-100,
                subtable-pager-pagel:       $gray-100,
                subtable-shadow:            0px 10px 15px 1px rgba(#000000, 0.05),

                detail-icon-color:          $primary,
                detail-icon-color-hover:    $primary,

    // Pagination colors
                page-spacer:                1rem,
                page-size:                  2.25rem,
                page-bg:                    darken($gray-100, 2%),
                page-bg-hover:              $primary,
                page-bg-active:             $primary,

                page-color:                 $dark-50,
                page-color-hover:           theme-inverse-color('primary'),
                page-color-active:          theme-inverse-color('primary'),

                page-icon-color:            $gray-500,
                page-icon-color-hover:      theme-inverse-color('primary'),
                page-icon-color-active:     theme-inverse-color('primary'),

                page-info-bg:               darken($gray-100, 2%),
                page-info-bg-hover:         $primary,
                page-info-color:            $dark-50,
                page-info-color-hover:      theme-inverse-color('primary')
) !default;

// Customers headbands
$customers-headbands: (
                "primary": transparent linear-gradient(86deg, #16B7F4 0%, #1845BF 100%) 0% 0% no-repeat padding-box,
                "danger": transparent linear-gradient(86deg, #C42635 0%, #9F1320 100%) 0% 0% no-repeat padding-box,
                "green": transparent linear-gradient(86deg, #71D9AD 0%, #0AAC68 100%) 0% 0% no-repeat padding-box
);

// Leads colors
$leads-type : (
                local: $dark-blue,
                local_apv: kt-get($kt-state-colors, primary, base),
                local_vo: $dark-blue,
                local_vd: $dark-blue,
                local_vn: $dark-blue,
                local_home_charge: $dark-blue,
                online: kt-get($kt-state-colors, primary, base),
                vo: kt-get($kt-state-colors, primary, base),
                vn: kt-get($kt-state-colors, primary, base),
                vd: kt-get($kt-state-colors, primary, base),
                reprise: kt-get($kt-state-colors, primary, base),
                local_reprise: $dark-blue,
                local_achat_cash: $dark-blue,
                achat_cash: kt-get($kt-state-colors, primary, base),
                achat_cash_abandon: kt-get($kt-state-colors, primary, base),
                afr_subscriber: kt-get($kt-state-colors, primary, base),
                achat: kt-get($kt-state-colors, success, base),
                prestation_apv: kt-get($kt-state-colors, success, base),
                securycar_disaster: #FF4A11,
                securycar_renewal: #FF4A11,
                securycar_termination: #FF4A11,
                securycar_resume: #FF4A11,
                commande_full_web: kt-get($kt-state-colors, primary, base),
                other: kt-get($kt-state-colors, success, base),
                planet_vo: kt-get($kt-state-colors, danger, base),
                constructeur: kt-get($kt-state-colors, warning, base),
                prestation_atelier: kt-get($kt-state-colors, primary, base),
                marketing_campaign_vo: $purple,
                marketing_campaign_vn: $purple,
                dms_net: kt-get($kt-state-colors, success, base),
                dms_icar: kt-get($kt-state-colors, success, base),
                captives_fi: kt-get($kt-state-colors, success, secondary),
                siv: kt-get($kt-state-colors, success, base),
                audit_tscans: kt-get($kt-state-colors, secondary, base),
                salesforce: kt-get($kt-state-colors, primary, base),
                fleetback: kt-get($kt-state-colors, secondary, base),
                icare: kt-get($kt-state-colors, danger, secondary),
                mecaplanning: kt-get($kt-state-colors, secondary, base),
                contrat_de_garantie: kt-get($kt-state-colors, primary, base),
                fid_vo: $green,
                fid_vn: $green,
                adictiz: kt-get($kt-state-colors, primary, base),
                contrat_de_service_opteven: kt-get($kt-state-colors, success, base),
                afr_financing: kt-get($kt-state-colors, primary, base),
                proposition_commerciale: kt-get($kt-state-colors, primary, base),
                offre_commerciale: kt-get($kt-state-colors, primary, base)
);

// Leads source
$leads-source: (
                apv: kt-get($kt-state-colors, primary, base),
                dealership: $dark-blue,
                dealership_commerce: $dark-blue,
                dealership_website: kt-get($kt-state-colors, primary, base),
                dealership_home_charge: $turquoise,
                fidcar: kt-get($kt-state-colors, primary, base),
                site_concession: kt-get($kt-state-colors, primary, base),
                afr: kt-get($kt-state-colors, primary, base),
                atelier_afr: kt-get($kt-state-colors, primary, base),
                marketing: #8950FC,
                commande_full_web: kt-get($kt-state-colors, primary, base),
                securycar: kt-get($kt-state-colors, danger, secondary),
                dms_net: kt-get($kt-state-colors, success, base),
                dms_icar: kt-get($kt-state-colors, success, base),
                captives_fi: kt-get($kt-state-colors, success, secondary),
                planet_vo: kt-get($kt-state-colors, danger, base),
                siv: kt-get($kt-state-colors, success, base),
                manufacturer: kt-get($kt-state-colors, warning, base),
                constructeur: kt-get($kt-state-colors, warning, base),
                marketing_campaign: $purple,
                tscan: kt-get($kt-state-colors, secondary, base),
                salesforce: kt-get($kt-state-colors, primary, base),
                fleetback: kt-get($kt-state-colors, secondary, base),
                icare: kt-get($kt-state-colors, danger, secondary),
                mecaplanning: kt-get($kt-state-colors, secondary, base),
                contrat_de_garantie: kt-get($kt-state-colors, primary, base),
                adictiz: kt-get($kt-state-colors, primary, base),
                contrat_de_service_opteven: kt-get($kt-state-colors, success, base),
                proposition_commerciale: kt-get($kt-state-colors, primary, base),
                offre_commerciale: kt-get($kt-state-colors, primary, base),
                la_centrale: $dark-blue,
);

.bg-disabled{
    background-color: #F3F6F9 !important;
}

@mixin light-scrollbar($size: 8px, $track: #F2F3F8, $thumb: #BABAC0) {

  &::-webkit-scrollbar {
    background-color: $track;
    border-radius: $size;
    width: $size;
    height: $size;
  }

  &::-webkit-scrollbar-track {
    background-color: $track;
    border-radius: 16px;
    border: solid 15px transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $thumb;
    border-radius: 16px;
  }

  &::-webkit-scrollbar-button {
    display:none;
  }

  &::-webkit-scrollbar-track-piece {
    background: transparent;
  }
};

.dashboard-stock__card--clickable{
  cursor: pointer;
  &:hover{
    border: 1px solid kt-get($kt-state-colors, light-gray, inverse);
  }
  &__text{
    color: kt-get($kt-font-color, text);
  }
}
